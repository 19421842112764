<template>
  <div class="user h100">
    <el-card class="h100 mainCard" id="mainCard">
      <div id="userId">
        {{ userId }}
      </div>
      <el-tabs v-model="activeName" type="card" class="mainTabs">
        <el-tab-pane :label="t('current_users')" name="first" class="h100 mainTabsItem">
          <CurrentUsers />
        </el-tab-pane>
        <el-tab-pane :label="t('pending_user_approval')" name="second" class="h100 mainTabsItem">
          <PendingUserApproval />
        </el-tab-pane>
        <el-tab-pane :label="t('existing_licenses')" name="third" class="h100 mainTabsItem">
          <PendingLicenseApproval />
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import CurrentUsers from './components/CurrentUsers'
import PendingLicenseApproval from './components/PendingLicenseApproval'
import PendingUserApproval from './components/PendingUserApproval'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
const { t } = useI18n()
const store = useStore()
const activeName = ref('first')
const userId = ref('')
userId.value = store.getters.userInfo.userId
</script>

<style lang="scss" scoped>
#mainCard {
  position: relative;
  #userId {
    font-size: 20px;
    height: 52px;
    line-height: 52px;
    position: absolute;
    color: #ffffff;
    letter-spacing: 0;
    left: 20px;
    font-weight: 700;
    top: 20px;
  }
  .mainTabsItem {
    margin-top: 20px;
  }
}
#mainCard::after {
  content: '';
  background: var(--el-card-border-color);
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 90px;
}
</style>
