<template>
  <div class="padding-user-approval">
    <div>
      <el-table
        :data="tableData"
        :key="tableHeight"
        :height="tableHeight"
        fit
        header-cell-class-name="divTabelHeader"
        row-class-name="divTabelRow"
        cell-class-name="divTabelRowCell"
        size="mini"
        style="width: 100%"
      >
        <!--<el-table-column type="selection" width="55" />-->
        <el-table-column :label="t('slno')" prop="slno" width="100"></el-table-column>
        <el-table-column :label="t('user_name')" prop="userId"></el-table-column>
        <el-table-column prop="approvalstatus" :label="t('approval_status')">
          <template #default="{ row }">
            <div>
              <span>{{ row.approvalstatus ? t('approvaled') : t('waiting_for_approval') }}</span>
            </div>
          </template>
        </el-table-column>
<!--        <el-table-column prop="buildingType" :label="t('building_type')"></el-table-column>
        <el-table-column prop="sitename" :label="t('site_name')"></el-table-column>-->
        <el-table-column prop="location" :label="t('location')"></el-table-column>
        <el-table-column prop="password" :label="t('password')"></el-table-column>
        <el-table-column prop="role" :label="t('role')">
          <template #default="{ row }">
            <div>
              <span> {{ row.role === '1' ? t('admin') : t('user') }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="t('operations')" width="220">
          <template #default="{ row }">
            <el-button type="danger" size="small" @click="approvalTheUser(row.userId)">
              {{ t('approval_user') }}
            </el-button>
            <!--<el-button type="primary" size="small" @click="CancelTheUser(row.userId)">-->
            <!--  Cancel-->
            <!--</el-button>-->
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[2, 5, 10, 20]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script setup>
// 数据相关
import { onActivated, ref, onMounted } from 'vue'
import { approveUser, getAllPendingLoginUsers } from '@/api/user'
import { ElMessageBox, ElMessage } from 'element-plus'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const approvalEnum = new Map()
approvalEnum.set('approved', 1)

const tableData = ref([])
const total = ref(0)
const page = ref(1)
const size = ref(10)
// 获取数据的方法
const getListData = async () => {
  const result = await getAllPendingLoginUsers({
    page: page.value - 1,
    size: size.value
  })
  tableData.value = result.content.list.filter(
    x => x.approvalstatus !== approvalEnum.get('approved')
  )
  // tableData.value = result.list
  total.value = result.content.total
}

// 处理导入用户后数据不重新加载的问题
onActivated(getListData)

// 分页相关
/**
 * size 改变触发
 */
const handleSizeChange = currentSize => {
  size.value = currentSize
  getListData()
}

/**
 * 页码改变触发
 */
const handleCurrentChange = currentPage => {
  page.value = currentPage
  getListData()
}

const approvalTheUser = async userId => {
  ElMessageBox.confirm(t('approve_Users_confirm'), t('approve_Users'), {
    confirmButtonText: t('confirm'),
    cancelButtonText: t('cancel'),
    customClass: 'divConfirm',
    cancelButtonClass: 'divConcancelButton'
  })
    .then(async () => {
      await toApproveUser(userId)
    })
    .catch(() => {})
}

const toApproveUser = async function (userId) {
  try {
    await approveUser({
      approvalstatus: 1,
      buildingType: 'string',
      location: 'string',
      password: 'string',
      role: 'string',
      slno: 0,
      userId: userId
    })
    ElMessage({
      message: t('approve') + t('success'),
      grouping: true,
      type: 'success',
      customClass: 'divSuccessElMessage'
    })
    await getListData()
  } catch (err) {
    ElMessage({
      message: t('approve') + t('error'),
      grouping: true,
      type: 'error',
      customClass: 'divSuccessElMessage'
    })
  }
}

const tableHeight = ref(500)
onMounted(() => {
  tableHeight.value = document.getElementById('mainCard').clientHeight - 193
})
</script>

<style lang="scss" scoped>
.padding-user-approval {
  .header {
    margin-bottom: 22px;
    text-align: right;
  }

  .pagination {
    margin-top: 20px;
    text-align: center;
  }
}
</style>
