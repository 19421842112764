import i18n from '@/i18n'

export const validatePassword = () => {
  return (rule, value, callback) => {
    if (value === '' || value === undefined) {
      callback(new Error(i18n.global.t('password') + i18n.global.t('must_be_filled')))
    } else if (value.length < 6) {
      callback(new Error(i18n.global.t('password') + i18n.global.t('must_more_than_6_length')))
    } else {
      callback()
    }
  }
}

export const validateUserName = () => {
  return (rule, value, callback) => {
    if (value === '' || value === undefined) {
      callback(new Error(i18n.global.t('user_name') + i18n.global.t('must_be_filled')))
    } else if (value.length > 20) {
      callback(new Error(i18n.global.t('user_name') + i18n.global.t('must_less_than_20')))
    } else if (!/[a-zA-Z]|\d/.test(value)) {
      callback(
        new Error(i18n.global.t('user_name') + i18n.global.t('cannot_includes_chinese_letters'))
      )
    } else {
      callback()
    }
  }
}

export const validateEmail = () => {
  return (rule, value, callback) => {
    if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)) {
      callback(new Error(i18n.global.t('need_correct_email')))
    } else {
      callback()
    }
  }
}
