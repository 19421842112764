<template>
  <div class="add-new-user">
    <el-dialog
      :title="t('add_new_user')"
      :model-value="modelValue"
      @close="closed"
      width="30%"
      custom-class="divConfirm"
    >
      <div class="content">
        <el-form
          :model="addUserForm"
          :rules="registerRules"
          ref="addUserFormRef"
          size="small"
          label-width="120px"
        >
          <el-form-item prop="username" :label="t('Username')">
            <el-input
              placeholder="Username"
              v-model="addUserForm.username"
              name="username"
              type="text"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="password" :label="t('password')">
            <el-input
              placeholder="Password"
              v-model="addUserForm.password"
              name="password"
              type="text"
            >
            </el-input>
          </el-form-item>

          <el-form-item prop="siteName" :label="t('site_name')">
            <el-input
              placeholder="Site Name"
              v-model="addUserForm.siteName"
              name="siteName"
              type="text"
            >
            </el-input>
          </el-form-item>

          <el-form-item prop="siteLocation" :label="t('site_Location')">
            <el-input
              placeholder="Site Location"
              v-model="addUserForm.siteLocation"
              name="siteLocation"
              type="text"
            >
            </el-input>
          </el-form-item>

          <el-form-item prop="email" :label="t('email')">
            <el-input placeholder="Email" v-model="addUserForm.email" name="email" type="text">
            </el-input>
          </el-form-item>

          <el-form-item prop="isApprovePDLicense" class="is-approve-pd-license">
            <el-checkbox
              v-model="addUserForm.isApprovePDLicense"
              :label="t('pd_license')"
              size="large"
            />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closed">{{ t('cancel') }}</el-button>
          <el-button type="primary" @click="submit"> {{ t('submit') }} </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue'
import { createNewUsers } from '@/api/user'
import { ElMessage } from 'element-plus'
import { validatePassword, validateUserName } from '@/views/login/rule'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
defineProps({
  modelValue: {
    type: Boolean,
    required: true
  }
})

/**
 * @description: 数据源
 */
const registerForm = ref()
registerForm.value = {}
registerForm.value.isApprovePDLicense = false

/**
 * @description: 验证规则
 */
const registerRules = ref({
  username: [
    {
      required: true,
      trigger: 'blur',
      validator: validateUserName()
    }
  ],
  password: [
    {
      required: true,
      trigger: 'blur',
      validator: validatePassword()
    }
  ]
})

const addUserFormRef = ref(null)

const addUserForm = ref()
addUserForm.value = {}
addUserForm.value.isApprovePDLicense = false

const emits = defineEmits(['update:modelValue', 'getListData'])

/**
 * 关闭
 */
const closed = () => {
  emits('update:modelValue', false)
  addUserForm.value = {}
}

const submit = () => {
  addUserFormRef.value.validate(async valid => {
    if (!valid) return
    const userInfo = {
      approvalstatus: 0,
      email: addUserForm.value.email,
      isApprovePDLicense: Number(addUserForm.value.isApprovePDLicense),
      location: addUserForm.value.siteLocation,
      password: addUserForm.value.password,
      role: 0,
      userId: addUserForm.value.username
    }
    try {
      await createNewUsers(userInfo)
      ElMessage({
        message: t('created') + t('success'),
        grouping: true,
        type: 'success',
        customClass: 'divSuccessElMessage'
      })
      emits('getListData')
      closed()
    } catch (e) {
      console.error(e)
      ElMessage({
        message: t('created') + t('error'),
        grouping: true,
        type: 'error',
        customClass: 'divSuccessElMessage'
      })
    }
  })
}
</script>

<style scoped lang="scss">
.my-info {
  .content {
    font-size: 16px;
    font-weight: 600;
  }
}
</style>
